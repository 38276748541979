/* eslint-disable react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import PorfolioCard from '../components/PorfolioCard/PorfolioCard';

export const pageQuery = graphql`
  query PortfolioPageQuery {
    baylor: file(relativePath: { eq: "portfolio/baylor-app-25.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    usDermatology: file(relativePath: { eq: "portfolio/us-dermatology.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    novaHomeCare: file(relativePath: { eq: "portfolio/nova-home-care.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    americanSamoa: file(relativePath: { eq: "portfolio/american-samoa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lymphomaResearchFoundation: file(
      relativePath: { eq: "portfolio/lymphoma-research-foundation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    umassMemorial: file(relativePath: { eq: "portfolio/umass-memorial.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    massTestingPlatform: file(
      relativePath: { eq: "portfolio/mass-testing-platform.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Porfolio = ({ data }) => {
  return (
    <>
      <Seo
        title="Medical Web Experts Case Studies | Medical Web Experts"
        description="Medical Web Experts engineers custom HIPAA-compliant web and mobile-based solutions exclusively for healthcare companies. Explore our portfolio and case studies."
        canonical="/portfolio/"
        schema={`
        {
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": "1",
              "item": {
                "@id": "https://www.medicalwebexperts.com",
                "name": "HOME",
                "image": "https://www.medicalwebexperts.com/images/mwe.png"
              }
            },
            {
              "@type": "ListItem",
              "position": "2",
              "item": {
                "@id": "https://www.medicalwebexperts.com/portfolio/",
                "name": "YOUR SUCCESS. OUR TEAM.",
                "image": "https://www.medicalwebexperts.com/images/work/porfolio/umass-porfolio.d4beeb28b553cab8.png"
              }
            }
          ]
        }
        `}
      />
      <BrandGrid colored />
      {/* Pages with Img Main Hero */}
      <div className="d-flex align-items-sm-center full-height-lg ui-layout__main--nmt">
        <Container>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg={{ span: 5, offset: 1 }}>
              <h1 className="mb-5 text-center text-lg-start h1--center-lg">
                Our Portfolio
              </h1>
              <p className="mt-4 mb-5 font-size-lg font-weight-light  text-center text-lg-start">
                Healthcare&apos;s Trusted Provider of Web Development and
                Technology Solutions since 2003
              </p>
              <div className="text-center text-lg-start mb-4">
                <Button as={Link} variant="primary" to="/contact-us">
                  start Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* About Content */}
      <Container>
        {/* Our Partners */}
        <Row className="mb-4">
          <Col className="text-center">
            <h2 className="h1 h1--center">Our Work</h2>
          </Col>
        </Row>

        {/* Nav Tabs */}
        <Row className="justify-content-center">
          <Col>
            <Tabs
              defaultActiveKey="all"
              id="uncontrolled-tab-example"
              className="justify-content-center mb-6 pb-3 pb-lg-0 unstyled"
            >
              <Tab eventKey="all" title="All">
                {/* Nav Tab Content */}
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - Baylor"
                  featuredImage={data.baylor}
                  name="Baylor Scott & White Heart and Vascular Hospital"
                  description={
                    <p>
                      <strong>Project:</strong> Mobile app, Patient-led tool for
                      chronic disease management, Wearable device integration
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      Baylor Scott & White Heart and Vascular Hospital (with
                      locations in Dallas, Fort Worth, and Waxahachie) wanted to
                      increase patient interest and participation in educational
                      programs with a mobile app as a central part of their
                      digital front door strategy. They needed a tool with
                      unique and custom hospital-specific features that would
                      serve as a companion to Baylor’s systemwide clinical app
                      and encourage patients to actively participate in managing
                      their heart health.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <p className="mb-0">
                      Medical Web Experts developed a custom tool for patients
                      to manage chronic heart conditions via a mobile app. The
                      Baylor Heart Center App engages patients and encourages
                      them to take an active role in their heart health with
                      features like medication tracking and guided meditation
                      for stress management. MWE develops, maintains, and hosts
                      the Baylor Heart Center mobile app suite, which supports
                      iPhone, iPad, and Apple Watch.
                    </p>
                  }
                  footerDescription={
                    <p className="mb-0">
                      <i>
                        The Find a Provider feature for the Baylor Heart Center
                        App earned the highest honor in the 2020 eHealthcare
                        Leadership Awards for Best Doctor Directory.
                      </i>
                    </p>
                  }
                  links={[
                    {
                      to: '/mhealth-apps/',
                      label: 'Mobile App Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.usDermatology}
                  name="U.S. Dermatology Partners"
                  description={
                    <p>
                      <strong>Project:</strong> Digital front door, eCommerce,
                      Revenue cycle management, Integrations
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      U.S. Dermatology Partners was challenged by disjointed
                      tech stacks of eight separate digital platforms that
                      slowed day-to-day administrative work and fragmented the
                      patient experience. They wanted an intuitive solution that
                      would improve their digital patient experience and
                      streamline internal operations, but they couldn’t find a
                      stock product that met their needs.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <p className="mb-0">
                      Medical Web Experts developed a custom digital front door,
                      integrated with the client’s PM and EHR solutions, that
                      incorporates or replaces the third-party solutions in the
                      client’s previous tech stack. The result is a one-stop
                      shop for patients and an efficient administrative portal
                      that improves internal efficiencies, lowers costs, and
                      reduces administrative error.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/digital-front-door-development/',
                      label: 'Digital Front Door',
                    },
                    {
                      to: '/integrated-delivery-networks-web-app-development/',
                      label: 'Integrations',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.novaHomeCare}
                  name="Nova Home Care"
                  description={
                    <p>
                      <strong>Project:</strong> Job application and employee
                      portal, Customer relationship management (CRM) for user
                      management
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      Nova Home Care, a home care agency based in Indianapolis,
                      IN, wanted to enhance their service delivery and
                      operational efficiency as they planned for expansion, but
                      they lacked an online presence for branding and client
                      acquisition. They also depended on documentation,
                      including a paper-based job application and employment
                      process. With no pre-built products that met their needs,
                      they needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed a custom web application
                        and digital solution that helped Nova Home Care
                        transition from paper-based documentation to an
                        efficient digital workflow. The new digital workflow
                        includes a job seeker portal that streamlines the job
                        applicant and employment process and a
                        password-protected employee portal that optimizes
                        workflows. This comprehensive solution also includes a
                        mobile-responsive, public-facing website where clients
                        can discover Nova Home Care’s services.
                      </p>
                      <p className="mb-0">
                        Nova Home Care has leveraged the website and portal to
                        grow its presence in five states across the US,
                        including Indiana, Arizona, Connecticut, Minnesota, and
                        Colorado.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Employee Portal',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Job Seeker Portal',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.americanSamoa}
                  name="American Samoa Department of Health"
                  description={
                    <p>
                      <strong>Project:</strong> Repatriation portal, Security,
                      Customs and immigration portal
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      During the COVID-19 pandemic, many American Samoa citizens
                      were stranded in the United States following the closure
                      of borders. The American Samoa government needed a
                      streamlined process for the repatriation of their
                      citizens, but they were still relying on an outdated
                      system, which contributed to a slow and tedious
                      repatriation process. With no repatriation products on the
                      market, American Samoa needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed – in under a month – a
                        custom web portal and website to help streamline and
                        facilitate the application, review, and confirmation of
                        applications for the repatriation of American Samoan
                        citizens during the COVID-19 pandemic. The portal
                        allowed authorities to efficiently review and confirm
                        applications, facilitating the safe and organized return
                        of citizens.
                      </p>
                      <p className="mb-0">
                        Once borders reopened and the COVID-19 pandemic
                        subsided, Medical Web Experts re-engineered the
                        repatriation portal into a digital customs and
                        immigration portal for international visitors and
                        tourists, through which each visitor completes an
                        immigration form to receive a unique QR code that border
                        patrol officers scan.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.lymphomaResearchFoundation}
                  name="Lymphoma Research Foundation"
                  description={
                    <p>
                      <strong>Project:</strong> Mobile app, Patient-led tool for
                      chronic disease management, HIPAA-compliant hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Lymphoma Research Foundation wanted to update their
                      Focus on Lymphoma mobile app. With no product on the
                      market offering their entire list of desired features or
                      allowing them to customize the solution to convey their
                      unique brand identity, the Lymphoma Research Foundation
                      turned to Medical Web Experts.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts develops, hosts, and maintains a
                        custom and revised version of the Focus on Lymphoma
                        mobile app for patients to better manage a lymphoma
                        diagnosis and treatment.
                      </p>
                      <p className="mb-0">
                        The Focus on Lymphoma app is strategically developed to
                        promote empathetic patient engagement through a unique
                        feature set, educational materials, and tools to address
                        patients’ needs.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Mobile App Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.umassMemorial}
                  name="UMass Memorial Medical Center: Division of Medical Toxicology"
                  description={
                    <p>
                      <strong>Project:</strong> Custom website
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Division of Medical Toxicology team at UMass Memorial
                      Medical Center wanted a unique digital presence that would
                      help them build their brand, showcase their work, and
                      recruit medical trainees, but they weren’t sure how to
                      create an engaging solution that speaks to their diverse
                      audience of colleagues, medical trainees, and patients and
                      their families.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed and executed a custom
                        mobile-responsive website design and multimedia content
                        strategy that reflects the personality of the UMass
                        Memorial Medical Center’s Division of Medical Toxicology
                        using animations for educational videos, custom logo
                        designs, and custom photography.
                      </p>
                      <p className="mb-0">
                        Highlights of our branding efforts include creating
                        complex instructional videos on how to use Narcan® in
                        the event of an overdose for their Overdose Prevention
                        Fund. The client established this fund to raise money
                        for research aimed at preventing overdose and saving
                        lives.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/design-case-study-umass-tox.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.massTestingPlatform}
                  name="Mass COVID-19 Testing Platform"
                  description={
                    <p>
                      <strong>Project:</strong> Laboratory information system
                      integration, Mobile-responsive web app, HIPAA-compliant
                      hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      A multi-location, Minnesota-based laboratory was
                      overwhelmed with the rise in requests for drive-thru
                      COVID-19 testing and needed a fully functional, efficient
                      solution within weeks to cope with the increased demand
                      for testing appointments, results generation, and
                      payments.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        In under six weeks, Medical Web Experts developed and
                        implemented a custom mobile-responsive web app that
                        fully integrated with and leveraged the client’s
                        pre-existing laboratory information system, having the
                        ability to scale if future laboratory testing demands
                        increase. The new platform allows the client to securely
                        share results, in a HIPAA-compliant way, with patients
                        and securely share relevant statistics with state
                        departments.
                      </p>
                      <p className="mb-0">
                        As a result, the client increased their daily testing
                        capacity by 900%, with 95% of test results delivered
                        within 24 hours.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/CustomLabPortalDevelopment.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Integrations',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="mobile-apps" title="Mobile Apps">
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - Baylor"
                  featuredImage={data.baylor}
                  name="Baylor Scott & White Heart and Vascular Hospital"
                  description={
                    <p>
                      <strong>Project:</strong> Mobile app, Patient-led tool for
                      chronic disease management, Wearable device integration
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      Baylor Scott & White Heart and Vascular Hospital (with
                      locations in Dallas, Fort Worth, and Waxahachie) wanted to
                      increase patient interest and participation in educational
                      programs with a mobile app as a central part of their
                      digital front door strategy. They needed a tool with
                      unique and custom hospital-specific features that would
                      serve as a companion to Baylor’s systemwide clinical app
                      and encourage patients to actively participate in managing
                      their heart health.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <p className="mb-0">
                      Medical Web Experts developed a custom tool for patients
                      to manage chronic heart conditions via a mobile app. The
                      Baylor Heart Center App engages patients and encourages
                      them to take an active role in their heart health with
                      features like medication tracking and guided meditation
                      for stress management. MWE develops, maintains, and hosts
                      the Baylor Heart Center mobile app suite, which supports
                      iPhone, iPad, and Apple Watch.
                    </p>
                  }
                  footerDescription={
                    <p className="mb-0">
                      <i>
                        The Find a Provider feature for the Baylor Heart Center
                        App earned the highest honor in the 2020 eHealthcare
                        Leadership Awards for Best Doctor Directory.
                      </i>
                    </p>
                  }
                  links={[
                    {
                      to: '/mhealth-apps/',
                      label: 'Mobile App Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.lymphomaResearchFoundation}
                  name="Lymphoma Research Foundation"
                  description={
                    <p>
                      <strong>Project:</strong> Mobile app, Patient-led tool for
                      chronic disease management, HIPAA-compliant hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Lymphoma Research Foundation wanted to update their
                      Focus on Lymphoma mobile app. With no product on the
                      market offering their entire list of desired features or
                      allowing them to customize the solution to convey their
                      unique brand identity, the Lymphoma Research Foundation
                      turned to Medical Web Experts.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts develops, hosts, and maintains a
                        custom and revised version of the Focus on Lymphoma
                        mobile app for patients to better manage a lymphoma
                        diagnosis and treatment.
                      </p>
                      <p className="mb-0">
                        The Focus on Lymphoma app is strategically developed to
                        promote empathetic patient engagement through a unique
                        feature set, educational materials, and tools to address
                        patients’ needs.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Mobile App Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="custom-develop" title="Custom Develop">
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.usDermatology}
                  name="U.S. Dermatology Partners"
                  description={
                    <p>
                      <strong>Project:</strong> Digital front door, eCommerce,
                      Revenue cycle management, Integrations
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      U.S. Dermatology Partners was challenged by disjointed
                      tech stacks of eight separate digital platforms that
                      slowed day-to-day administrative work and fragmented the
                      patient experience. They wanted an intuitive solution that
                      would improve their digital patient experience and
                      streamline internal operations, but they couldn’t find a
                      stock product that met their needs.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <p className="mb-0">
                      Medical Web Experts developed a custom digital front door,
                      integrated with the client’s PM and EHR solutions, that
                      incorporates or replaces the third-party solutions in the
                      client’s previous tech stack. The result is a one-stop
                      shop for patients and an efficient administrative portal
                      that improves internal efficiencies, lowers costs, and
                      reduces administrative error.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/digital-front-door-development/',
                      label: 'Digital Front Door',
                    },
                    {
                      to: '/integrated-delivery-networks-web-app-development/',
                      label: 'Integrations',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.novaHomeCare}
                  name="Nova Home Care"
                  description={
                    <p>
                      <strong>Project:</strong> Job application and employee
                      portal, Customer relationship management (CRM) for user
                      management
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      Nova Home Care, a home care agency based in Indianapolis,
                      IN, wanted to enhance their service delivery and
                      operational efficiency as they planned for expansion, but
                      they lacked an online presence for branding and client
                      acquisition. They also depended on documentation,
                      including a paper-based job application and employment
                      process. With no pre-built products that met their needs,
                      they needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed a custom web application
                        and digital solution that helped Nova Home Care
                        transition from paper-based documentation to an
                        efficient digital workflow. The new digital workflow
                        includes a job seeker portal that streamlines the job
                        applicant and employment process and a
                        password-protected employee portal that optimizes
                        workflows. This comprehensive solution also includes a
                        mobile-responsive, public-facing website where clients
                        can discover Nova Home Care’s services.
                      </p>
                      <p className="mb-0">
                        Nova Home Care has leveraged the website and portal to
                        grow its presence in five states across the US,
                        including Indiana, Arizona, Connecticut, Minnesota, and
                        Colorado.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Employee Portal',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Job Seeker Portal',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.americanSamoa}
                  name="American Samoa Department of Health"
                  description={
                    <p>
                      <strong>Project:</strong> Repatriation portal, Security,
                      Customs and immigration portal
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      During the COVID-19 pandemic, many American Samoa citizens
                      were stranded in the United States following the closure
                      of borders. The American Samoa government needed a
                      streamlined process for the repatriation of their
                      citizens, but they were still relying on an outdated
                      system, which contributed to a slow and tedious
                      repatriation process. With no repatriation products on the
                      market, American Samoa needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed – in under a month – a
                        custom web portal and website to help streamline and
                        facilitate the application, review, and confirmation of
                        applications for the repatriation of American Samoan
                        citizens during the COVID-19 pandemic. The portal
                        allowed authorities to efficiently review and confirm
                        applications, facilitating the safe and organized return
                        of citizens.
                      </p>
                      <p className="mb-0">
                        Once borders reopened and the COVID-19 pandemic
                        subsided, Medical Web Experts re-engineered the
                        repatriation portal into a digital customs and
                        immigration portal for international visitors and
                        tourists, through which each visitor completes an
                        immigration form to receive a unique QR code that border
                        patrol officers scan.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.lymphomaResearchFoundation}
                  name="Lymphoma Research Foundation"
                  description={
                    <p>
                      <strong>Project:</strong> Mobile app, Patient-led tool for
                      chronic disease management, HIPAA-compliant hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Lymphoma Research Foundation wanted to update their
                      Focus on Lymphoma mobile app. With no product on the
                      market offering their entire list of desired features or
                      allowing them to customize the solution to convey their
                      unique brand identity, the Lymphoma Research Foundation
                      turned to Medical Web Experts.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts develops, hosts, and maintains a
                        custom and revised version of the Focus on Lymphoma
                        mobile app for patients to better manage a lymphoma
                        diagnosis and treatment.
                      </p>
                      <p className="mb-0">
                        The Focus on Lymphoma app is strategically developed to
                        promote empathetic patient engagement through a unique
                        feature set, educational materials, and tools to address
                        patients’ needs.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Mobile App Development',
                    },
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.umassMemorial}
                  name="UMass Memorial Medical Center: Division of Medical Toxicology"
                  description={
                    <p>
                      <strong>Project:</strong> Custom website
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Division of Medical Toxicology team at UMass Memorial
                      Medical Center wanted a unique digital presence that would
                      help them build their brand, showcase their work, and
                      recruit medical trainees, but they weren’t sure how to
                      create an engaging solution that speaks to their diverse
                      audience of colleagues, medical trainees, and patients and
                      their families.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed and executed a custom
                        mobile-responsive website design and multimedia content
                        strategy that reflects the personality of the UMass
                        Memorial Medical Center’s Division of Medical Toxicology
                        using animations for educational videos, custom logo
                        designs, and custom photography.
                      </p>
                      <p className="mb-0">
                        Highlights of our branding efforts include creating
                        complex instructional videos on how to use Narcan® in
                        the event of an overdose for their Overdose Prevention
                        Fund. The client established this fund to raise money
                        for research aimed at preventing overdose and saving
                        lives.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/design-case-study-umass-tox.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.massTestingPlatform}
                  name="Mass COVID-19 Testing Platform"
                  description={
                    <p>
                      <strong>Project:</strong> Laboratory information system
                      integration, Mobile-responsive web app, HIPAA-compliant
                      hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      A multi-location, Minnesota-based laboratory was
                      overwhelmed with the rise in requests for drive-thru
                      COVID-19 testing and needed a fully functional, efficient
                      solution within weeks to cope with the increased demand
                      for testing appointments, results generation, and
                      payments.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        In under six weeks, Medical Web Experts developed and
                        implemented a custom mobile-responsive web app that
                        fully integrated with and leveraged the client’s
                        pre-existing laboratory information system, having the
                        ability to scale if future laboratory testing demands
                        increase. The new platform allows the client to securely
                        share results, in a HIPAA-compliant way, with patients
                        and securely share relevant statistics with state
                        departments.
                      </p>
                      <p className="mb-0">
                        As a result, the client increased their daily testing
                        capacity by 900%, with 95% of test results delivered
                        within 24 hours.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/CustomLabPortalDevelopment.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Integrations',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="web-design" title="Web Design">
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.novaHomeCare}
                  name="Nova Home Care"
                  description={
                    <p>
                      <strong>Project:</strong> Job application and employee
                      portal, Customer relationship management (CRM) for user
                      management
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      Nova Home Care, a home care agency based in Indianapolis,
                      IN, wanted to enhance their service delivery and
                      operational efficiency as they planned for expansion, but
                      they lacked an online presence for branding and client
                      acquisition. They also depended on documentation,
                      including a paper-based job application and employment
                      process. With no pre-built products that met their needs,
                      they needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed a custom web application
                        and digital solution that helped Nova Home Care
                        transition from paper-based documentation to an
                        efficient digital workflow. The new digital workflow
                        includes a job seeker portal that streamlines the job
                        applicant and employment process and a
                        password-protected employee portal that optimizes
                        workflows. This comprehensive solution also includes a
                        mobile-responsive, public-facing website where clients
                        can discover Nova Home Care’s services.
                      </p>
                      <p className="mb-0">
                        Nova Home Care has leveraged the website and portal to
                        grow its presence in five states across the US,
                        including Indiana, Arizona, Connecticut, Minnesota, and
                        Colorado.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Employee Portal',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Job Seeker Portal',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.americanSamoa}
                  name="American Samoa Department of Health"
                  description={
                    <p>
                      <strong>Project:</strong> Repatriation portal, Security,
                      Customs and immigration portal
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      During the COVID-19 pandemic, many American Samoa citizens
                      were stranded in the United States following the closure
                      of borders. The American Samoa government needed a
                      streamlined process for the repatriation of their
                      citizens, but they were still relying on an outdated
                      system, which contributed to a slow and tedious
                      repatriation process. With no repatriation products on the
                      market, American Samoa needed a custom solution.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed – in under a month – a
                        custom web portal and website to help streamline and
                        facilitate the application, review, and confirmation of
                        applications for the repatriation of American Samoan
                        citizens during the COVID-19 pandemic. The portal
                        allowed authorities to efficiently review and confirm
                        applications, facilitating the safe and organized return
                        of citizens.
                      </p>
                      <p className="mb-0">
                        Once borders reopened and the COVID-19 pandemic
                        subsided, Medical Web Experts re-engineered the
                        repatriation portal into a digital customs and
                        immigration portal for international visitors and
                        tourists, through which each visitor completes an
                        immigration form to receive a unique QR code that border
                        patrol officers scan.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.umassMemorial}
                  name="UMass Memorial Medical Center: Division of Medical Toxicology"
                  description={
                    <p>
                      <strong>Project:</strong> Custom website
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Division of Medical Toxicology team at UMass Memorial
                      Medical Center wanted a unique digital presence that would
                      help them build their brand, showcase their work, and
                      recruit medical trainees, but they weren’t sure how to
                      create an engaging solution that speaks to their diverse
                      audience of colleagues, medical trainees, and patients and
                      their families.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed and executed a custom
                        mobile-responsive website design and multimedia content
                        strategy that reflects the personality of the UMass
                        Memorial Medical Center’s Division of Medical Toxicology
                        using animations for educational videos, custom logo
                        designs, and custom photography.
                      </p>
                      <p className="mb-0">
                        Highlights of our branding efforts include creating
                        complex instructional videos on how to use Narcan® in
                        the event of an overdose for their Overdose Prevention
                        Fund. The client established this fund to raise money
                        for research aimed at preventing overdose and saving
                        lives.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/design-case-study-umass-tox.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="case-studies" title="Case Studies">
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.umassMemorial}
                  name="UMass Memorial Medical Center: Division of Medical Toxicology"
                  description={
                    <p>
                      <strong>Project:</strong> Custom website
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      The Division of Medical Toxicology team at UMass Memorial
                      Medical Center wanted a unique digital presence that would
                      help them build their brand, showcase their work, and
                      recruit medical trainees, but they weren’t sure how to
                      create an engaging solution that speaks to their diverse
                      audience of colleagues, medical trainees, and patients and
                      their families.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        Medical Web Experts developed and executed a custom
                        mobile-responsive website design and multimedia content
                        strategy that reflects the personality of the UMass
                        Memorial Medical Center’s Division of Medical Toxicology
                        using animations for educational videos, custom logo
                        designs, and custom photography.
                      </p>
                      <p className="mb-0">
                        Highlights of our branding efforts include creating
                        complex instructional videos on how to use Narcan® in
                        the event of an overdose for their Overdose Prevention
                        Fund. The client established this fund to raise money
                        for research aimed at preventing overdose and saving
                        lives.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/design-case-study-umass-tox.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <div className="mb-6" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.massTestingPlatform}
                  name="Mass COVID-19 Testing Platform"
                  description={
                    <p>
                      <strong>Project:</strong> Laboratory information system
                      integration, Mobile-responsive web app, HIPAA-compliant
                      hosting
                    </p>
                  }
                  challengeTitle="The Challenge"
                  challengeText={
                    <p className="mb-0">
                      A multi-location, Minnesota-based laboratory was
                      overwhelmed with the rise in requests for drive-thru
                      COVID-19 testing and needed a fully functional, efficient
                      solution within weeks to cope with the increased demand
                      for testing appointments, results generation, and
                      payments.
                    </p>
                  }
                  solutionTitle="The Solution"
                  solutionText={
                    <>
                      <p>
                        In under six weeks, Medical Web Experts developed and
                        implemented a custom mobile-responsive web app that
                        fully integrated with and leveraged the client’s
                        pre-existing laboratory information system, having the
                        ability to scale if future laboratory testing demands
                        increase. The new platform allows the client to securely
                        share results, in a HIPAA-compliant way, with patients
                        and securely share relevant statistics with state
                        departments.
                      </p>
                      <p className="mb-0">
                        As a result, the client increased their daily testing
                        capacity by 900%, with 95% of test results delivered
                        within 24 hours.
                      </p>
                    </>
                  }
                  link="https://www.medicalwebexperts.com/pdf/CustomLabPortalDevelopment.pdf"
                  linkLabel="see case study"
                  links={[
                    {
                      to: '/healthcare-mobile-app-development/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/portfolio/',
                      label: 'Integrations',
                    },
                  ]}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Porfolio;
